/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import { AnimationOnScroll } from 'react-animation-on-scroll';
// import 'animate.css/animate.min.css';

/* Component Imports */

import IconTextCard from "./sub-component/IconTextCard";

const useStyles = makeStyles((theme) => ({
  megaContainer: {
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      padding: "0rem 0.5rem 1rem 0.5rem",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "0rem 1.5rem 1rem 1.5rem",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "0rem 3rem 1rem 3rem",
    },
    [theme.breakpoints.only("md")]: {
      padding: "0rem 4rem 1rem 4rem",
    },
    [theme.breakpoints.only("lg")]: {
      padding: "0rem 4rem 1rem 4rem",
    },
    /* XLG breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.lg + 48)]: {
      padding: "0rem 5rem 1rem 5rem",
    },
    /* Full HD breakpoint */
    [theme.breakpoints.up("xl")]: {
      padding: "0rem 23rem 1rem 23rem",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      padding: "0rem 36rem 1rem 36rem",
    },
    /* XXXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
      padding: "0rem 72rem 1rem 72rem",
    },
    margin: "0rem 0rem 6rem 0rem",
    [theme.breakpoints.down("xs")]: {
      margin: "0rem 0rem 3rem 0rem",
    },
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
      gap: "3rem",
    },
    justifyContent: "space-between",
    padding: "2rem 0rem 2rem 0rem",
    alignItems: "center",
    width: "100%",
    gap: "2rem",
  },
}));

const SectionThree = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.megaContainer}>
        <div className={classes.sectionContainer}>
          {/* <AnimationOnScroll animateIn="animate__fadeIn" duration={1}> */}
          <IconTextCard
            icon="/images/icons/heavie/index/building1.svg"
            title="Modern"
            description="Scientific and definitive construction solutions"
          />
          {/* </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeIn" duration={2}> */}
          <IconTextCard
            icon="/images/icons/heavie/index/focus.svg"
            title="Minimal"
            description="Design aesthetics embodying minimalism"
          />
          {/* </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeIn" duration={3}> */}
          <IconTextCard
            icon="/images/icons/heavie/index/mega1.svg"
            title="Mega"
            description="Building the bigger picture"
          />
          {/* </AnimationOnScroll> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SectionThree;

// /* Common Imports */

// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';

// /* Component Imports */

// import IconTextCard from './sub-component/IconTextCard';

// const useStyles = makeStyles((theme) => ({
//   megaContainer: {
//     /* XXS breakpoint */
//     [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
//       padding: '0rem 0.5rem 1rem 0.5rem',
//     },
//     [theme.breakpoints.only('xs')]: {
//       padding: '0rem 1.5rem 1rem 1.5rem',
//     },
//     [theme.breakpoints.only('sm')]: {
//       padding: '0rem 3rem 1rem 3rem',
//     },
//     [theme.breakpoints.only('md')]: {
//       padding: '0rem 4rem 1rem 4rem',
//     },
//     [theme.breakpoints.only('lg')]: {
//       padding: '0rem 4rem 1rem 4rem',
//     },
//     /* XLG breakpoint */
//     [theme.breakpoints.up(theme.breakpoints.values.lg + 48)]: {
//       padding: '0rem 5rem 1rem 5rem',
//     },
//     /* Full HD breakpoint */
//     [theme.breakpoints.up('xl')]: {
//       padding: '0rem 23rem 1rem 23rem',
//     },
//     /* XXL breakpoint */
//     [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
//       padding: '0rem 36rem 1rem 36rem',
//     },
//     /* XXXL breakpoint */
//     [theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
//       padding: '0rem 72rem 1rem 72rem',
//     },
//     margin: '0rem 0rem 6rem 0rem',
//     [theme.breakpoints.down('xs')]: {
//       margin: '0rem 0rem 3rem 0rem',
//     },
//   },
//   sectionContainer: {
//     display: 'flex',
//     flexDirection: 'row',
//     [theme.breakpoints.down('xs')]: {
//       flexDirection: 'column',
//       justifyContent: 'space-between',
//       alignItems: 'flex-start',
//       gap: '3rem',
//     },
//     justifyContent: 'space-between',
//     padding: '2rem 0rem 2rem 0rem',
//     alignItems: 'center',
//     width: '100%',
//     gap: '2rem',
//   },
// }));

// const SectionThree = () => {
//   const classes = useStyles();

//   return (
//     <React.Fragment>
//       <div className={classes.megaContainer}>
//         <div className={classes.sectionContainer}>
//           <IconTextCard
//             icon="/images/icons/heavie/index/building.svg"
//             title="Modern"
//             description="Scientific and definitive construction solutions"
//           />
//           <IconTextCard
//             icon="/images/icons/heavie/index/focus.svg"
//             title="Minimal"
//             description="Design asthetics embodying “minimalism”"
//           />
//           <IconTextCard
//             icon="/images/icons/heavie/index/cityscape.svg"
//             title="Mega"
//             description="Building the bigger picture"
//           />
//         </div>
//       </div>
//     </React.Fragment>
//   );
// };

// export default SectionThree;
