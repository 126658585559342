/* Common Imports */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import SectionOneNew from "./SectionOneNew";

/* Component Imports */

/* Data Imports */
import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  megaContainer: {
    objectFit: "cover",
    width: "100%",
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      padding: "0rem 0rem 1rem 0rem",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "0rem 0rem 1rem 0rem",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "0rem 0rem 1rem 0rem",
    },
    [theme.breakpoints.only("md")]: {
      padding: "0rem 0rem 1rem 0rem",
    },
    [theme.breakpoints.only("lg")]: {
      padding: "0rem 0rem 1rem 0rem",
    },
    /* XLG breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.lg + 48)]: {
      padding: "0rem 0rem 1rem 0rem",
    },
    /* Full HD breakpoint */
    [theme.breakpoints.up("xl")]: {
      padding: "0rem 0rem 1rem 0rem",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      padding: "0rem 0rem 1rem 0rem",
    },
    /* XXXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
      padding: "0rem 0rem 1rem 0rem",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0rem 0rem 0rem 0rem",
    },
  },
  illustrationSizer: {
    objectFit: "contain",
    // height: "calc(100vh - 4rem)",
    width: "100%",
    height: "100%",
    // [theme.breakpoints.down("sm")]: {
    //   objectFit: "contain",
    //   width: "100%",
    //   height: "100%",
    // },
    // [theme.breakpoints.down("xs")]: {
    //   width: "100%",
    //   height: "auto",
    // },
    // [theme.breakpoints.up(theme.breakpoints.values.md + 60)]: {
    //   objectFit: "contain",
    //   width: "100%",
    //   height: "100%",
    // },
    // [theme.breakpoints.up(theme.breakpoints.values.md + 80)]: {
    //   objectFit: "cover",
    //   height: "calc(100vh - 4rem)",
    //   width: "100%",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   objectFit: "cover",
    //   height: "calc(100vh - 4rem)",
    //   width: "100%",
    // },
  },
}));

const PropertyImage = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.megaContainer}>
        <img
          src="/images/icons/heavie/index/exterior_day_view.webp"
          alt={AppConfig.imageAltText}
          width={512}
          height={512}
          loading="lazy"
          referrerPolicy="no-referrer"
          className={classes.illustrationSizer}
        />
      </div>
    </React.Fragment>
  );
};

export default PropertyImage;
