/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Data Imports */

import AppConfig from "../../../../AppConfig";
import { Typography } from "@material-ui/core";

/* Define all styles */

const useStyles = makeStyles((theme) => ({
  iconTextRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
    gap: "2rem",
  },
  text: {
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
    justifyContent: "flex-start",
  },
  heading: {
    fontWeight: 500,
    fontSize: "1.625rem",
    lineHeight: "1.95rem",
    color: "#000000",
    width: "100%",
    marginBottom: "0.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2rem",
    },
  },
  subHeading: {
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.5rem",
    color: "#666666",
    width: "100%",
    wordBreak: "break-word",
  },
}));

const IconTextCard = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={`${classes.iconTextRow} ${props.className}`}>
        <img
          alt={AppConfig.imageAltText}
          loading="lazy"
          referrerPolicy="no-referrer"
          height={50}
          width={50}
          src={props.icon ? props.icon : "/images/icons/config/area.svg"}
        />
        <div className={classes.text}>
          <Typography
            variant="body2"
            component="span"
            className={classes.heading}
          >
            {props.title ? props.title : "Modern"}
          </Typography>
          <Typography
            variant="body2"
            component="span"
            className={classes.subHeading}
          >
            {props.description
              ? props.description
              : "Scientific and definitive construction solutions"}
          </Typography>
        </div>
      </div>
    </React.Fragment>
  );
};

export default IconTextCard;
