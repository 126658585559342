/* Common Imports */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */
import { Typography } from "@material-ui/core";

/* Data Imports */
import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  megaContainer: {
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      padding: "0rem 0.5rem 1rem 0.5rem",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "0rem 1.5rem 0rem 1.5rem",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "0rem 3rem 1rem 3rem",
    },
    [theme.breakpoints.only("md")]: {
      padding: "0rem 4rem 1rem 4rem",
    },
    [theme.breakpoints.only("lg")]: {
      padding: "0rem 4rem 1rem 4rem",
    },
    /* XLG breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.lg + 48)]: {
      padding: "0rem 5rem 1rem 5rem",
    },
    /* Full HD breakpoint */
    [theme.breakpoints.up("xl")]: {
      padding: "0rem 23rem 1rem 23rem",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      padding: "0rem 36rem 1rem 36rem",
    },
    /* XXXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
      padding: "0rem 72rem 1rem 72rem",
    },
    margin: "0rem 0rem 3rem 0rem",
    [theme.breakpoints.down("xs")]: {
      margin: "0rem 0rem 0rem 0rem",
    },
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      gap: "0rem",
    },
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    gap: "3rem",
  },
  text: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  heading: {
    margin: "0rem 0rem 2rem 0rem",
    color: "#74378E",
    fontWeight: 400,
    background: "-webkit-linear-gradient(180deg, #74378E 0%, #502761 100%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fillColor": "transparent",
    fontSize: "2.5rem",
    lineHeight: "3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.75rem",
      lineHeight: "2.125rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.75rem",
      lineHeight: "2.125rem",
    },
  },
  heading1: {
    width: "100%",
    margin: "0rem 0rem 1rem 0rem",
    color: "#4A225B",
    fontWeight: 400,
    background: "-webkit-linear-gradient(180deg, #74378E 0%, #502761 100%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fillColor": "transparent",
    fontSize: "1.25rem",
    lineHeight: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.125rem",
    },
  },
  subHeading: {
    margin: "0rem 0rem 1rem 0rem",
    width: "100%",
    color: "#202020",
    fontWeight: 400,
    fontSize: "1.125rem",
    lineHeight: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      lineHeight: "1.625rem",
    },
  },
  illustrationSizer: {
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      height: "200px",
      width: "270px",
    },
    [theme.breakpoints.only("xs")]: {
      height: "260px",
      width: "270px",
    },
    [theme.breakpoints.only("sm")]: {
      height: "400px",
      width: "600px",
    },
    [theme.breakpoints.only("md")]: {
      height: "250px",
      width: "400px",
    },
    [theme.breakpoints.only("lg")]: {
      height: "400px",
      width: "500px",
    },
    [theme.breakpoints.only("xl")]: {
      height: "380px",
      width: "500px",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      height: "450px",
      width: "600px",
    },
    [theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
      height: "450px",
      width: "650px",
    },
  },
}));

const OriginSection = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.megaContainer}>
        <div className={classes.sectionContainer}>
          <div className={classes.text}>
            <Typography variant="h1" component="h1" className={classes.heading}>
              Origin
            </Typography>
            <Typography
              variant="body2"
              component="span"
              className={classes.subHeading}
            >
              What is the one thing on earth that is so heavy that it cannot be
              moved?
            </Typography>
            <Typography
              variant="body2"
              component="span"
              className={classes.subHeading}
            >
              What is the one thing on earth that will stay in the same place
              forever?
            </Typography>
            <Typography
              variant="body2"
              component="span"
              className={classes.subHeading}
            >
              What is the one thing on earth which has been in existence since
              the beginning of time?
            </Typography>
            <Typography
              variant="body2"
              component="span"
              className={classes.heading1}
            >
              A Piece of Land.
            </Typography>
            <Typography
              variant="body2"
              component="span"
              className={classes.subHeading}
            >
              A piece of land is what empires have been built on.
            </Typography>
            <Typography
              variant="body2"
              component="span"
              className={classes.subHeading}
            >
              A piece of land is the only thing on earth which can provide
              shelter for humanity to thrive.
            </Typography>
            <Typography
              variant="body2"
              component="span"
              className={classes.subHeading}
            >
              A piece of land, once claimed and secured, can be an asset you can
              pass on for generations to come.
            </Typography>
          </div>
          <div className={classes.animation}>
            <img
              src="/images/icons/heavie/index/plot.svg"
              alt={AppConfig.imageAltText}
              width={512}
              height={512}
              loading="eager"
              referrerPolicy="no-referrer"
              className={classes.illustrationSizer}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OriginSection;
